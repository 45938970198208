import { Action, action, thunk, Thunk } from "easy-peasy";
import axios from "../../../common/axios";
import NotificationAlert from "../../../common/notification-alert";

interface ITemplatesRequest {
  security?: {
    APIKey: string;
    User: string;
  };
}

export interface ITemplate {
  id?: string;
  name?: string;
  clientID?: string;
  showName?: string;
  description?: string;
  surveys?: [];
}

interface ITemplates {
  Templates: ITemplate[];
  Template?: ITemplate;
  IsLoading: boolean;
}

interface ITemplateAction {
  setAllTemplate: Action<this, ITemplates>;
  setTemplate: Action<this, ITemplates>;
  setIsLoading: Action<this, boolean>;
}
interface ITemplateThunk {
  getAllTemplate: Thunk<this, ITemplatesRequest>;
}

interface ITemplateModel extends ITemplates, ITemplateAction, ITemplateThunk {}

const TemplateModel: ITemplateModel = {
  Templates: [],
  Template: {},
  IsLoading: true,
  setAllTemplate: action((state, payload) => {
    state.Templates = payload.Templates;
  }),
  setTemplate: action((state, payload) => {
    state.Template = payload.Template;
  }),

  getAllTemplate: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/certificate/template/Search", payload)
      .then((value: any) => {
        let temTemplate: ITemplates = {
          Templates: [],
          IsLoading: false,
        };
        value.data.templates.forEach((element: any) => {
          temTemplate.Templates?.push({
            id: element.id,
            name: element.name,
            showName: element.name,
            clientID: element?.clientID,
            surveys: element?.surveys,
            description: element?.description,
          });
        });

        action.setAllTemplate(temTemplate);
        action.setIsLoading(false);
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Error from api"
        );
        action.setIsLoading(false);
      });
  }),

  setIsLoading: action((state, payload) => {
    state.IsLoading = payload;
  }),
};

export default TemplateModel;
