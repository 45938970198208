import React, { useCallback, useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Container, Modal, Button, Row, Col } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import { useForm } from "react-hook-form";
import {
  useStoreActions,
  useStoreState,
} from "../../../../../store/models/model-hook";
import "./certificate.css";

const CertificateAdd: React.FC<any> = (props): JSX.Element => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [selected, setSelected] = useState<Option[]>([]);
  const [templateId, setTemplateId] = useState<String>("");

  const [loading, setLoading] = useState(false);
  const { setAllTemplate } = useStoreActions((actions) => ({
    setAllTemplate: actions.templateModel.getAllTemplate,
  }));

  const { Templates, IsLoading } = useStoreState((state) => ({
    Templates: state.templateModel.Templates,
    IsLoading: state.clientModel.IsLoading,
  }));

  const getRecords = useCallback(() => {
    setLoading(true);
    (async function () {
      await setAllTemplate({
        security: {
          APIKey: process.env.REACT_APP_API_KEY as string,
          User: user.attributes?.email as string,
        },
      });
    })().then(() => {
      setLoading(false);
    });
  }, [setAllTemplate, user.attributes?.email]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleClose = () => {
    props.closeModal();
  };

  // const handleOnSelect = (item: any) => {
  //   if (item) {
  //     console.log("On Select Type =========== ", typeof item);
  //     setSelected(item);
  //   } else {
  //     setSelected([]);
  //   }
  // };

  const resetForm = useCallback(
    async (result: any) => {
      reset(result);
    },
    [reset]
  );

  useEffect(() => {
    if (props.show) {
      let result;
      resetForm(result);
    }
  }, [props.show, resetForm]);

  const onSectionFormSubmit = (section: any) => {
    section.templateId = templateId;
    section.sourceID = "";
    section.buildingID = null;
    section.clientID = props.client?.id
    section.security = {
      APIKey: process.env.REACT_APP_API_KEY as string,
      User: user.attributes?.email as string,
    }
    setTemplateId("");
    setSelected([]);
    props.manageSection(section);
  };

  const handleOnSelect = (item: any) => {
    if (item) {
      setTemplateId(item.id);
    } else {
      setTemplateId("");
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        contentClassName="mysa-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.certificateId ? "Update Certificate" : "Add Certificate"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-new">
          <Container>
            <form
              className="create-form"
              onSubmit={handleSubmit(onSectionFormSubmit)}
            >
              <Row className="mb-3">
                <Col md={12}>
                  <label className="form-label">Templates</label>
                  <Typeahead
                    id="basic-example"
                    labelKey="showName"
                    onChange={(options: any) => {
                      setSelected(options);
                      handleOnSelect(options[0]);
                    }}
                    options={Templates}
                    placeholder="Choose a Template"
                    selected={selected}
                    clearButton
                  />
                  {errors.SectionName && (
                    <span className="error">This field is required</span>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="text-right offset-4 col-md-8 col-8">
                  <Button
                    id="approve-button"
                    className="create-btn"
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    id="reject-button"
                    className="cancel-btn"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </form>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CertificateAdd;
