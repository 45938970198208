import React, { useState, useEffect, useCallback } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import DataTable, { createTheme } from "react-data-table-component";
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import BreadCrumbPage from "../../../../../common/bread-crumb-page";
import {
  tabInside,
  tableStyles,
  mysaTableTheme,
} from "../../../../../common/components-style";
import {
  useStoreActions,
  useStoreState,
} from "../../../../../store/models/model-hook";
import { SpinLoader } from "../../../../../utils/spin-loader";
import { SetTableOptions } from "../../../../../shared/set-table-options";
import { CURRENT_PAGE, PAGE_SIZE } from "../../../../../utils/constants";
import eyeIcon from "../../../../../assets/images/table/eye.svg";
import { SurveyView } from "../index";

createTheme("mysaTable", mysaTableTheme, "light");

const SurveyList: React.FC<any> = ({ selected, breadList }): JSX.Element => {
  const { user } = useAuthenticator((context) => [context.user]);

  const [selectedCertificate, setSelectedCertificate] = useState({});
  const [showSurveyList, setShowSurveyList] = useState(false);

  const { setAllSurvey } = useStoreActions((actions) => ({
    setAllSurvey: actions.surveyModel.getAllSurvey,
  }));

  const { Surveys, TotalRows, IsLoading } = useStoreState((state) => ({
    Surveys: state.surveyModel.Surveys,
    TotalRows: state.certificateModel.TotalRows,
    IsLoading: state.clientModel.IsLoading,
  }));

  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(CURRENT_PAGE);

  const getRecords = useCallback(
    (page: number, size = perPage) => {
      setLoading(true);
      (async function () {
        await setAllSurvey({
          security: {
            APIKey: process.env.REACT_APP_API_KEY as string,
            User: user.attributes?.email as string,
          },
          current: page,
          pagesize: size,
          sortfield: "",
        });
      })().then(() => {
        setLoading(false);
      });
    },
    [setAllSurvey, perPage, user.attributes?.email]
  );

  useEffect(() => {
    getRecords(CURRENT_PAGE, perPage);
  }, [perPage, getRecords]);

  const columns = [
    {
      name: "Survey",
      selector: (row: any) => row.name,
    },
    {
      name: "Audit Issue",
      selector: (row: any) => "-",
    },
    {
      name: "Status",
      selector: (row: any) => "-",
    },
    {
      name: "Assessor",
      selector: (row: any) => {
        if (row.email) {
          return row.email;
        } else {
          return "-";
        }
      },
    },
    {
      name: "Action",
      button: true,
      style: [{ "justify-content": "left" }],
      cell: (row: any) => {
        return (
          <>
            <OverlayTrigger
              placement="top"
              delay={{ show: 50, hide: 10 }}
              overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
            >
              <img
                className="iconSizes"
                src={eyeIcon}
                alt=""
                onClick={() => {
                  handleRowClick(row);
                }}
              />
            </OverlayTrigger>
          </>
        );
      },
    },
  ];

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handlePerRowsChange = useCallback(
    (newPerPage: number, page: number) => {
      setPerPage(newPerPage);
      setCurrentPage(1);
    },
    []
  );

  const handleRowClick = (row?: object) => {
    if (row) {
      setSelectedCertificate(row);
      setShowSurveyList(true);
    }
  };
  const surveyBread = [
    {
      name: "Brand Management",
      href: "/brand-management",
      isDisabled: false,
    },
    { name: selected?.name, isDisabled: true },
  ];
  return (
    <>
      {(showSurveyList && (
        <SurveyView selected={selectedCertificate} breadList={surveyBread} />
      )) || (
          <>
            <SpinLoader isLoading={false} />
            <div style={tabInside}>
              <Row>
                <Col md={12}>
                  <BreadCrumbPage
                    links={[...breadList, { name: selected?.name, isDisabled: true }]}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "1%" }}>
                <Col md={12}>
                  <DataTable
                    columns={columns}
                    data={Surveys}
                    striped={true}
                    customStyles={tableStyles}
                    theme="mysaTable"
                    fixedHeader={true}
                    noDataComponent={<SetTableOptions loading={loading} />}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    progressPending={loading}
                    progressComponent={<SpinLoader />}
                    pagination
                    paginationServer
                    paginationTotalRows={10}
                    paginationDefaultPage={currentPage}
                  />
                </Col>
              </Row>
            </div>
          </>
        )}

    </>
  );
};

export default SurveyList;
