import React, { useState, useEffect, useCallback } from "react";
import { Col, Form, Row, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useAuthenticator } from "@aws-amplify/ui-react";
import DataTable, { createTheme } from "react-data-table-component";

import {
    mysaTableTheme,
    tableStyles
} from "../../../../common/components-style";
import { tabInside } from "../../../../common/components-style";
import Helper from "../../../../utils/helper";
import BreadCrumbPage from "../../../../common/bread-crumb-page";
import { SpinLoader } from "../../../../utils/spin-loader";
import { CURRENT_PAGE, PAGE_SIZE } from "../../../../utils/constants";
import {
    useStoreActions,
    useStoreState,
} from "../../../../store/models/model-hook";
import { SetTableOptions } from "../../../../shared/set-table-options";
import eyeIcon from "../../../../assets/images/table/eye.svg";
import CertificateAdd from "./certificateAdd";
import deactivatedIcon from "../../../../assets/images/table/deactivated.svg";
import activatedIcon from "../../../../assets/images/table/activated.svg";
import SurveyList from "../survey/surveyList";

createTheme("mysaTable", mysaTableTheme, "light");

const Certificate: React.FC<any> = ({ selected, breadList }): JSX.Element => {
    const { user } = useAuthenticator((context) => [context.user]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(CURRENT_PAGE);
    const [perPage, setPerPage] = useState(PAGE_SIZE);
    const [certificateId, setCertificateId] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [selectedPropertyCertificate, setSelectedPropertyCertificate] = useState({});
    const [showPropertySurveyList, setPropertyShowSurveyList] = useState(false);

    const { getAllCertificate } = useStoreActions((actions) => ({
        getAllCertificate: actions.certificateModel.getAllCertificate,
    }));

    const { setAllCertificate, createSurvey } = useStoreActions((actions) => ({
        setAllCertificate: actions.certificateModel.getAllCertificate,
        createSurvey: actions.certificateModel.createSurvey,
    }));

    const { Certificates, TotalRows, IsLoading } = useStoreState((state) => ({
        Certificates: state.certificateModel.Certificates,
        TotalRows: state.certificateModel.TotalRows,
        IsLoading: state.certificateModel.IsLoading,
    }));

    const getCertificateRecords = useCallback(
        (page: number, size = perPage) => {
            setLoading(true);
            (async function () {
                await getAllCertificate({
                    security: {
                        APIKey: process.env.REACT_APP_API_KEY as string,
                        User: user.attributes?.email as string,
                    },
                    buildingID: selected?.id,
                    "showExternal": false
                });
            })().then(() => {
                setLoading(false);
            });
        },
        [getAllCertificate, perPage, selected, user.attributes?.email]
    );

    useEffect(() => {
        getCertificateRecords(CURRENT_PAGE, perPage);
    }, [perPage, getCertificateRecords]);

    const handlePageChange = useCallback(
        (page: number) => {
            setCurrentPage(page);
            getCertificateRecords(page);
        },
        [getCertificateRecords]
    );

    const handlePerRowsChange = useCallback(
        (newPerPage: number, page: number) => {
            setPerPage(newPerPage);
            setCurrentPage(1);
            getCertificateRecords(page);
        },
        [getCertificateRecords]
    );

    const closeCertificate = () => {
        setShowForm(false);
        if (certificateId) {
            setCertificateId("");
        }
    };

    const handleCreateCertificate = useCallback(
        (d: any) => {
            (async function () {
                // TODO: Need to change create certificate response.
                await createSurvey(d);
            })().then(function () {
                closeCertificate();
                setTimeout(() => {
                    getCertificateRecords(CURRENT_PAGE, perPage);
                }, 2000);
            });
        },
        []
    );

    const addCertificate = (id?: string) => {
        setShowForm(true);
        if (id) {
            setCertificateId(id);
        }
    };

    const activateCertificate = (id?: string) => {
        console.log("Activate Certificate: ", id);
        // TODO: Need to dynamic this.
    };

    const handleRowClick = (idStr: string, row: any) => {
        if (idStr) {
            setSelectedPropertyCertificate(row);
            setPropertyShowSurveyList(true);
        }
    };

    const surveyBread = [
        {
            name: "Property Management",
            href: "/property-management",
            isDisabled: false,
        },
        { name: 'Test', isDisabled: true },
    ];
    const columns = [
        {
            name: "Certificate",
            selector: (row: any) => row.name,
        },
        {
            name: "Audit Issue",
            selector: (row: any) => "-",
        },
        {
            name: "Status",
            selector: (row: any) => "-",
        },
        {
            name: "Expires",
            selector: (row: any) => "-",
        },
        {
            name: "Assessor",
            selector: (row: any) => {
                if (row.email) {
                    return row.email;
                } else {
                    return "-";
                }
            },
        },
        {
            name: "Action",
            button: true,
            cell: (row: any) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 50, hide: 10 }}
                            overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
                        >
                            <img
                                className="iconSizes"
                                src={eyeIcon}
                                alt=""
                                onClick={() => {
                                    // console.log("Row ===== ", row);
                                    handleRowClick(row?.id, row);
                                }}
                            />
                        </OverlayTrigger>
                        {
                            (row?.active) ? <>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 50, hide: 10 }}
                                    overlay={<Tooltip id="tooltip-table-top">Activate</Tooltip>}
                                >
                                    <img
                                        className="iconSizes"
                                        src={activatedIcon}
                                        alt=""
                                        style={{ height: "20px" }}
                                        onClick={() => {
                                            activateCertificate(row);
                                        }}
                                    />
                                </OverlayTrigger>
                            </> : <>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 50, hide: 10 }}
                                    overlay={<Tooltip id="tooltip-table-top">Activate</Tooltip>}
                                >
                                    <img
                                        className="iconSizes"
                                        src={deactivatedIcon}
                                        alt=""
                                        style={{ height: "20px" }}
                                        onClick={() => {
                                            activateCertificate(row);
                                        }}
                                    />
                                </OverlayTrigger>
                            </>
                        }
                    </>
                );
            },
        },
    ];
    ////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <>
            {(showPropertySurveyList && (
                <SurveyList selected={selectedPropertyCertificate} breadList={surveyBread} />
            )) || (
                    <>
                        <SpinLoader isLoading={IsLoading} />
                        <div style={tabInside}>
                            <Row>
                                <Col md={8}>
                                    <BreadCrumbPage
                                        links={[
                                            {
                                                name: "Property Management",
                                                href: "/brand-management",
                                                isDisabled: false,
                                            },
                                            { name: selected?.name, isDisabled: true },
                                        ]}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Button
                                        id="approve-button"
                                        className="float-right"
                                        onClick={() => {
                                            addCertificate();
                                        }}
                                    >
                                        <b>New Certificate</b>
                                    </Button>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1%" }}>
                                <Col md={12}>
                                    <DataTable
                                        columns={columns}
                                        data={Certificates}
                                        striped={true}
                                        customStyles={tableStyles}
                                        theme="mysaTable"
                                        fixedHeader={true}
                                        noDataComponent={<SetTableOptions loading={loading} />}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        progressPending={loading}
                                        progressComponent={<SpinLoader />}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={TotalRows}
                                        paginationDefaultPage={currentPage}
                                    />
                                </Col>
                            </Row>
                            <CertificateAdd
                                certificateId={certificateId}
                                show={showForm}
                                selected={selected}
                                closeModal={closeCertificate}
                                manageSection={handleCreateCertificate}
                            // client={Client}
                            />
                        </div>
                    </>
                )}
        </>
    );
};

export default Certificate;
