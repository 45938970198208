import { Action, action, thunk, Thunk } from "easy-peasy";
import axios from "../../../common/axios";
import NotificationAlert from "../../../common/notification-alert";

interface ISurveysRequest {
  security?: {
    APIKey: string;
    User: string;
  };
  certificateID?: string;
  current?: number;
  pagesize?: number;
  sortfield?: string;
  sortorder?: string;
}

export interface ISurvey {
  id?: string;
  name?: string;
  clientID?: string;
  showName?: string;
  canDelete?: boolean;
  description?: string;
  sections?: [];
}

interface ISurveys {
  Surveys: ISurvey[];
  Survey?: ISurvey;
  TotalRows: number;
  IsLoading: boolean;
}

interface ISurveyAction {
  setAllSurvey: Action<this, ISurveys>;
  setSurvey: Action<this, ISurveys>;
  setTotalRows: Action<this, number>;
  setIsLoading: Action<this, boolean>;
}
interface ISurveyThunk {
  getAllSurvey: Thunk<this, ISurveysRequest>;
}

interface ISurveyModel extends ISurveys, ISurveyAction, ISurveyThunk {}

const SurveyModel: ISurveyModel = {
  Surveys: [],
  Survey: {},
  TotalRows: 0,
  IsLoading: true,
  setAllSurvey: action((state, payload) => {
    state.Surveys = payload.Surveys;
  }),
  setSurvey: action((state, payload) => {
    state.Survey = payload.Survey;
  }),
  setTotalRows: action((state, payload) => {
    state.TotalRows = payload;
  }),

  getAllSurvey: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/survey/Search", payload)
      .then((value: any) => {
        let temSurvey: ISurveys = {
          Surveys: [],
          TotalRows: 0,
          IsLoading: false,
        };
        value.data.surveys.forEach((element: any) => {
          temSurvey.Surveys?.push({
            id: element.id,
            name: element.name,
            showName: element.name,
            clientID: element?.clientID,
            sections: element?.sections,
            canDelete: element?.canDelete,
            description: element?.description,
          });
        });

        action.setAllSurvey(temSurvey);
        action.setTotalRows(temSurvey.Surveys.length);
        action.setIsLoading(false);
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Error from api"
        );
        action.setIsLoading(false);
      });
  }),

  setIsLoading: action((state, payload) => {
    state.IsLoading = payload;
  }),
};

export default SurveyModel;
