import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { tabInside } from "../../../../common/components-style";
import Helper from "../../../../utils/helper";
import BreadCrumbPage from "../../../../common/bread-crumb-page";
import { SpinLoader } from "../../../../utils/spin-loader";

const ClientDetail: React.FC<any> = ({ Client, IsLoading }): JSX.Element => {
  return (
    <>
      <SpinLoader isLoading={IsLoading} />
      <div style={tabInside}>
        <Row>
          <Col md={8}>
            <BreadCrumbPage
              links={[
                {
                  name: "Brand Management",
                  href: "/brand-management",
                  isDisabled: false,
                },
                { name: Client?.name, isDisabled: true },
              ]}
            />
          </Col>
          <Col md={4}></Col>
        </Row>
        <div className="jumbotron">
          <Row>
            <Col md={1}></Col>
            <Col md={10}>
              <Row className="formSpace">
                <Col md={6} style={{ textAlign: "right" }}>
                  <Form.Label>Contact</Form.Label>
                </Col>
                <Col md={6} style={{ textAlign: "left" }}>
                  <p className="lead">{Client?.contact}</p>
                </Col>
              </Row>

              <Row className="formSpace">
                <Col md={6} style={{ textAlign: "right" }}>
                  <Form.Label>Email Address</Form.Label>
                </Col>
                <Col md={6} style={{ textAlign: "left" }}>
                  <p className="lead">{Client?.email}</p>
                </Col>
              </Row>
              <Row className="formSpace">
                <Col md={6} style={{ textAlign: "right" }}>
                  <Form.Label>Phone Number</Form.Label>
                </Col>
                <Col md={6} style={{ textAlign: "left" }}>
                  <p className="lead">
                    {new Helper().formatePhoneNumber(Client?.phone ?? "-")}
                  </p>
                </Col>
              </Row>
              <Row className="formSpace">
                <Col md={6} style={{ textAlign: "right" }}>
                  <Form.Label>Address 1</Form.Label>
                </Col>
                <Col md={6} style={{ textAlign: "left" }}>
                  <p className="lead">Address one</p>
                </Col>
              </Row>
              <Row className="formSpace">
                <Col md={6} style={{ textAlign: "right" }}>
                  <Form.Label>Address 2</Form.Label>
                </Col>
                <Col md={6} style={{ textAlign: "left" }}>
                  <p className="lead">Address Two</p>
                </Col>
              </Row>
              <Row className="formSpace">
                <Col md={6} style={{ textAlign: "right" }}>
                  <Form.Label>City</Form.Label>
                </Col>
                <Col md={6} style={{ textAlign: "left" }}>
                  <p className="lead">City</p>
                </Col>
              </Row>
              <Row className="formSpace">
                <Col md={6} style={{ textAlign: "right" }}>
                  <Form.Label>Country</Form.Label>
                </Col>
                <Col md={6} style={{ textAlign: "left" }}>
                  <p className="lead">Country</p>
                </Col>
              </Row>
              <Row className="formSpace">
                <Col md={6} style={{ textAlign: "right" }}>
                  <Form.Label>Zip/PostalCode</Form.Label>
                </Col>
                <Col md={6} style={{ textAlign: "left" }}>
                  <p className="lead">12345</p>
                </Col>
              </Row>
            </Col>
            <Col md={1}></Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ClientDetail;
