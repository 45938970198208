import React from "react";
// import { DefaultTheme } from "react-search-autocomplete/dist/config/config";

////////////////// theme color ///////////////////////////
export const $mysaBlack = "#000000";
export const $mysaBlackLight = "#5f5f5f";
export const $mysaBlue = "#19AFE6";
export const $mysaTeal = "#2DBDB6";
export const $mysaYellow = " #fAC90E";
export const $mysaMegenta = "#E7008C";
export const $mysaGrey = "#F2F2F2";
export const $mysaGreyDark = "#00000021";

// 3px solid #00000021
export const $mysaWhite = "#FFFFFF";

//////////////////// searchable dropdown color ////////////////////////
// const searchableDropDownFont = $mysaBlack;
// const searchableDropDownBackground = $mysaWhite;
// const searchableDropDownBorder = $mysaBlack;

////////////////////table color /////////////////////
const themeColor = $mysaGrey;
const stripedBackground = $mysaGrey;
const headerCellBackground = $mysaBlack; //$mysaGrey;
const headerCellFont = $mysaWhite; //$mysaBlack;

///////////////get display sizes ///////////////////
const { innerHeight: height } = window;

///////////////// side bar style ////////////////////////////
export const sideMenuStyle = {
  height: height,
  background: $mysaBlack,
  width: "80px",
  paddingTop: "30px",
  position: "fixed",
  zIndex: 1,
} as React.CSSProperties;

/////////////// side bar tooltip style ///////////////////
export const toolTipStyle = {
  marginLeft: "20px",
  fontWeight: "bold",
} as React.CSSProperties;

/////////////////////side bar icon style /////////////////////////
export const iconDivStyle = {
  width: "40px",
  display: "block",
  margin: "auto",
  paddingBottom: "10px",
} as React.CSSProperties;

//////////////////// tabs style ///////////////////////
export const tabDivStyle = { marginLeft: "5rem" } as React.CSSProperties;
export const navStyle = {
  width: "100%",
  background: $mysaGrey,
  height: height * 1.2,
  paddingTop: "25px",
} as React.CSSProperties;

export const BrandNavStyle = {
  width: "197px",
  background: $mysaGrey,
  height: height * 1.5,
  paddingTop: "25px",
  position: "fixed",
} as React.CSSProperties;

export const navContentStyle = {
  margin: "15px",
} as React.CSSProperties;

///////////////////// tab inside style ////////

export const tabInside = {
  marginTop: "2%",
  marginRight: "4%",
} as React.CSSProperties;

///////////////////////// table style /////////////////////////////
export const mysaTableTheme = {
  divider: {
    default: themeColor,
  },
} as React.CSSProperties;

export const tableStyles = {
  rows: {
    style: {
      minHeight: "72px",
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: $mysaGrey,
      },
    },
    stripedStyle: {
      background: stripedBackground,
    },
  },
  headRow: {
    style: {
      padding: "16px",
      background: headerCellBackground,
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      background: headerCellBackground,
      // borderRadius: 30,
      fontSize: "19px",
      color: headerCellFont,
      "&:not(:last-of-type)": {
        borderRight: `3px solid ${$mysaGrey}`,
      },
      "&:first-of-type": {
        paddingLeft: "0px",
      },
      borderRight: "none",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      fontSize: "16px",
      "&:first-of-type": {
        paddingLeft: "16px",
      },
    },
  },
  pagination: {
    style: {
      marginTop: "40px",
      borderTop: "none",
    },
  },
};

//////////////////////////// form button style //////////////////////////
export const formButton = {
  height: "40px",
  width: "300px",
  borderRadius: 10,
  paddingLeft: "30px",
  paddingRight: "30px",
  marginRight: "20px",
} as React.CSSProperties;

export const searchButton = {
  height: "40px",
  borderRadius: 10,
  paddingLeft: "30px",
  paddingRight: "30px",
  marginRight: "20px",
} as React.CSSProperties;

///page header
export const pageHeader = {
  fontWeight: "bolder",
  paddingTop: "25px",
} as React.CSSProperties;
