import { createStore } from "easy-peasy";
import { IStoreModel } from "./models/model-hook";
import clientModel from "./models/brand/client-model";
import surveyModel from "./models/certificate/survey-model";
import propertyModel from "./models/property/property-model";
import templateModel from "./models/certificate/template-model";
import certificateModel from "./models/certificate/certificate-model";
import assessorModel from "./models/user/assessor-model";

const storeModel: IStoreModel = {
  clientModel: clientModel,
  surveyModel: surveyModel,
  propertyModel: propertyModel,
  templateModel: templateModel,
  certificateModel: certificateModel,
  assessorModel: assessorModel
};

const store = createStore(storeModel);

export default store;
