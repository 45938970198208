import { createTypedHooks } from "easy-peasy";
import clientModel from "./brand/client-model";
import surveyModel from "./certificate/survey-model";
import propertyModel from "./property/property-model";
import templateModel from "./certificate/template-model";
import certificateModel from "./certificate/certificate-model";
import assessorModel from "./user/assessor-model";

export interface IStoreModel {
    clientModel: typeof clientModel;
    surveyModel: typeof surveyModel;
    propertyModel: typeof propertyModel;
    templateModel: typeof templateModel;
    certificateModel: typeof certificateModel;
    assessorModel: typeof assessorModel;
}

const { useStoreActions, useStoreState, useStoreDispatch, useStore } =
    createTypedHooks<IStoreModel>();

export { useStoreActions, useStoreState, useStoreDispatch, useStore };
