import { Tab, Row, Col, Nav } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  navContentStyle,
  navStyle,
  tabDivStyle,
} from "../../../common/components-style";
import {
  useStoreActions,
  useStoreState,
} from "../../../store/models/model-hook";
import ClientDetail from "./detail/detail";
import PropertyList from "./property/propertyList";
import { CertificateList } from "./certificate";

const BrandDetail: React.FC<any> = (): JSX.Element => {
  const [tabKey, setTabKey] = useState<any>("details");
  let { clientId } = useParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const { getClientById } = useStoreActions((actions) => ({
    getClientById: actions.clientModel.getClientById,
  }));
  const { Client, IsLoading } = useStoreState((state) => ({
    Client: state.clientModel.Client,
    IsLoading: state.clientModel.IsLoading,
  }));

  useEffect(() => {
    (async function () {
      await getClientById({
        security: {
          APIKey: process.env.REACT_APP_API_KEY as string,
          User: user.attributes?.email as string,
        },
        id: clientId,
      });
    })();
  }, [getClientById, clientId, user.attributes?.email]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div style={tabDivStyle}>
      <Tab.Container
        defaultActiveKey="details"
        key={tabKey}
        activeKey={tabKey}
        onSelect={(tab) => setTabKey(tab)}
      >
        <Row>
          <Col sm={2}>
            <Nav
              variant="pills"
              className="flex-column secondry-menu"
              style={navStyle}
            >
              <div style={{ marginTop: "0%" }}>
                <Nav.Item>
                  <Nav.Link eventKey="details">Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="certificates">Certificates</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="properties">Properties</Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Tab.Pane eventKey="details">
                <div style={navContentStyle}>
                  {/* <div> */}
                  <ClientDetail Client={Client} IsLoading={IsLoading} />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="certificates">
                <div style={navContentStyle}>
                  {/* <div> */}
                  <CertificateList Client={Client} IsLoading={IsLoading} />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="properties">
                <div style={navContentStyle}>
                  {/* <div> */}
                  <PropertyList Client={Client} IsLoading={IsLoading} />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default BrandDetail;
