import { Action, action, thunk, Thunk } from "easy-peasy";
import axios from "../../../common/axios";
import Helper from "../../../utils/helper";
import NotificationAlert from "../../../common/notification-alert";

interface IAssessorRequest {
    security?: {
        APIKey: string;
        User: string;
    };
    id?: string;
}

export interface IAssessor {
    id?: string;
    parent?: null;
    email?: string;
    clientID?: string;
    role?: string;
}

interface IAssessors {
    Assessors: IAssessor[];
    IsLoading: boolean;
}

interface IAssessorsAction {
    setAllAssessors: Action<this, IAssessors>;
    setIsLoading: Action<this, boolean>;
}
interface IAssessorsThunk {
    getAllAssesor: Thunk<this, IAssessorRequest>;
}

interface IAssessorModel extends IAssessors, IAssessorsAction, IAssessorsThunk { }

const assessorModel: IAssessorModel = {
    Assessors: [],
    IsLoading: true,
    setAllAssessors: action((state, payload) => {
        state.Assessors = payload.Assessors;
    }),

    getAllAssesor: thunk((action, payload) => {
        action.setIsLoading(true);
        axios
            .post("/user/Search", payload)
            .then((value: any) => {
                let temAssessor: IAssessors = {
                    Assessors: [],
                    IsLoading: false,
                };
                value.data.users.forEach((element: any) => {
                    temAssessor.Assessors?.push({
                        id: element.id,
                        email: element?.email,
                    });
                });
                action.setAllAssessors(temAssessor);
                action.setIsLoading(false);
            })
            .catch((error) => {
                const notification = new NotificationAlert();
                notification.createNotification(
                    "danger",
                    error.message,
                    "Error from api"
                );
                action.setIsLoading(false);
            });
    }),

    setIsLoading: action((state, payload) => {
        state.IsLoading = payload;
    }),
};

export default assessorModel;
