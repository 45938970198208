import React from "react";
// import { useAuthenticator } from "@aws-amplify/ui-react";
import { Col, Row } from "react-bootstrap";
import { pageHeader, tabInside } from "../../../../../common/components-style";
import { SpinLoader } from "../../../../../utils/spin-loader";
import BreadCrumbPage from "../../../../../common/bread-crumb-page";

const SurveyDetail: React.FC<any> = ({ selected, breadList }): JSX.Element => {
    //   const { user } = useAuthenticator((context) => [context.user]);
    return (
        <>
            <SpinLoader isLoading={false} />
            <div style={pageHeader}>
                {/* <h3>Survey Details</h3> */}
            </div>
            <div style={tabInside}>
                <Row>
                    <Col md={12}>
                        <BreadCrumbPage
                            links={[...breadList, { name: selected?.name, isDisabled: true }]}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: "1%" }}>
                    <Col md={12}>
                        <h1 key={selected?.name} style={{ fontSize: '24px' }} className="form-label question-title">
                            {selected?.name}
                        </h1>
                        {selected?.sections?.map((item: any) =>
                            <>
                                <h1 key={item?.id} style={{ fontSize: '24px' }} className="form-label question-title">
                                    {item?.name}
                                </h1>
                                <hr />

                                <Row>
                                    <Col md={4}>
                                        <label className="form-label">Brand Name</label>
                                    </Col>
                                    <Col md={8}>
                                        <p className="lead" >Test</p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <label className="form-label">Head Office Addres</label>
                                    </Col>
                                    <Col md={8}>
                                        <p className="lead">Test</p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <label className="form-label">Office Telephone Number</label>
                                    </Col>
                                    <Col md={8}>
                                        <p className="lead">Test</p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <label className="form-label">First name of main brand contact (this person will be chief brand admin in MYO)</label>
                                    </Col>
                                    <Col md={8}>
                                        <p className="lead">Test</p>
                                    </Col>
                                </Row>

                            </>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default SurveyDetail;
