import { Action, action, thunk, Thunk } from "easy-peasy";
import axios from "../../../common/axios";
import NotificationAlert from "../../../common/notification-alert";

interface IPropertyRequest {
  security?: {
    APIKey: string;
    User: string;
  };
  id?: string;
}

interface IPropertiesRequest {
  security?: {
    APIKey: string;
    User: string;
  };
  filter?: string;
  clientID?: string;
  current?: number;
  pagesize?: number;
  sortField?: string;
}

export interface IProperty {
  id?: string;
  name?: string;
  assessor?: string;
  city?: string;
  assessorInfo?: object;
  contactInfo?: object;
}

interface IProperties {
  Properties: IProperty[];
  Property?: IProperty;
  TotalRows: number;
  IsLoading: boolean;
}

interface IPropertyAction {
  setAllProperty: Action<this, IProperties>;
  setProperty: Action<this, IProperties>;
  setTotalRows: Action<this, number>;
  setIsLoading: Action<this, boolean>;
}
interface IPropertyThunk {
  getAllProperty: Thunk<this, IPropertiesRequest>;
  getPropertyById: Thunk<this, IPropertyRequest>;
  getPropertyByIdView: Thunk<this, IPropertyRequest>;
}

interface IPropertyModel extends IProperties, IPropertyAction, IPropertyThunk {}

const PropertyModel: IPropertyModel = {
  Properties: [],
  Property: {},
  TotalRows: 0,
  IsLoading: true,
  setAllProperty: action((state, payload) => {
    state.Properties = payload.Properties;
  }),
  setProperty: action((state, payload) => {
    state.Property = payload.Property;
  }),
  setTotalRows: action((state, payload) => {
    state.TotalRows = payload;
  }),

  getAllProperty: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/building/Search", payload)
      .then((value: any) => {
        let temProperty: IProperties = {
          Properties: [],
          TotalRows: 0,
          IsLoading: false,
        };
        value.data.buildings.forEach((element: any) => {
          temProperty.Properties?.push({
            id: element.id,
            name: element.buildingName,
            city: element.city,
            assessorInfo: element.assessorInfo,
            contactInfo: element.contactInfo,
          });
        });

        action.setAllProperty(temProperty);
        action.setTotalRows(temProperty.Properties.length);
        action.setIsLoading(false);
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Error from api"
        );
        action.setIsLoading(false);
      });
  }),

  getPropertyById: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/building/view", payload)
      .then((value: any) => {
        if (value.data.building) {
          let temProperty: IProperties = {
            Property: {
              id: value.data.building.id,
              name: value.data.building.buildingName,
              city: value.data.building.city,
              assessorInfo: value.data.building.assessorInfo,
              contactInfo: value.data.building.contactInfo,
            },
            Properties: [],
            TotalRows: 0,
            IsLoading: false,
          };
          action.setProperty(temProperty);
          action.setIsLoading(false);
        }
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Error from api"
        );
        action.setIsLoading(false);
      });
  }),
  getPropertyByIdView: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/building/get", payload)
      .then((value: any) => {
        if (value.data.building) {
          let temProperty: IProperties = {
            Property: {
              id: value.data.building.id,
              name: value.data.building.buildingName,
              city: value.data.building.city,
              assessorInfo: value.data.building.assessorInfo,
              contactInfo: value.data.building.contactInfo,
            },
            Properties: [],
            TotalRows: 0,
            IsLoading: false,
          };
          action.setProperty(temProperty);
          action.setIsLoading(false);
        }
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Error from api"
        );
        action.setIsLoading(false);
      });
  }),

  setIsLoading: action((state, payload) => {
    state.IsLoading = payload;
  }),
};

export default PropertyModel;
