import React from "react";
import { Route, Routes } from "react-router-dom";

import Brand from "../pages/brand-management/brand";
import BrandDetail from "../pages/brand-management/brand-detail";
import PropertyDetails from "../pages/property-management/property-details";

const Router: React.FC = (): JSX.Element => (
  <Routes>
    <Route path="/brand-management" element={<Brand />} />
    <Route path="/brand-management/:clientId" element={<BrandDetail />} />
    <Route path="/property-management/:buildingId" element={<PropertyDetails />} />
  </Routes>
);

export default Router;
