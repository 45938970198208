import React from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";

const BreadCrumbPage: React.FC<any> = ({ links }): JSX.Element => {
  return (
    <>
      <Row>
        <Col md={12}>
          <Breadcrumb>
            {links.map((link: any) => (
              <>
                {!link.isDisabled ? (
                  <Breadcrumb.Item href={link.href}>
                    {link.name}
                  </Breadcrumb.Item>
                ) : (
                  <Breadcrumb.Item active>{link.name}</Breadcrumb.Item>
                )}
              </>
            ))}
          </Breadcrumb>
        </Col>
      </Row>
    </>
  );
};

export default BreadCrumbPage;
