import React, { useCallback, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Col, Row, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import CertificateAdd from "./certificateAdd";
import { SurveyList } from "../index";
import {
    mysaTableTheme,
    tableStyles,
    tabInside,
} from "../../../../../common/components-style";
import BreadCrumbPage from "../../../../../common/bread-crumb-page";
import { SpinLoader } from "../../../../../utils/spin-loader";
import { SetTableOptions } from "../../../../../shared/set-table-options";
import DataTable, { createTheme } from "react-data-table-component";
import {
    useStoreActions,
    useStoreState,
} from "../../../../../store/models/model-hook";
import { CURRENT_PAGE, PAGE_SIZE } from "../../../../../utils/constants";
import eyeIcon from "../../../../../assets/images/table/eye.svg";
import deactivatedIcon from "../../../../../assets/images/table/deactivated.svg";
import activatedIcon from "../../../../../assets/images/table/activated.svg";

createTheme("mysaTable", mysaTableTheme, "light");

const CertificateList: React.FC<any> = ({ Client }): JSX.Element => {
    const { user } = useAuthenticator((context) => [context.user]);
    const { getAllCertificate, createSurvey } = useStoreActions((actions) => ({
        getAllCertificate: actions.certificateModel.getAllCertificate,
        createSurvey: actions.certificateModel.createSurvey,
    }));

    const { Certificates, TotalRows, IsLoading } = useStoreState((state) => ({
        Certificates: state.certificateModel.Certificates,
        TotalRows: state.certificateModel.TotalRows,
        IsLoading: state.clientModel.IsLoading,
    }));

    const [certificateId, setCertificateId] = useState("");
    const [selectedCertificate, setSelectedCertificate] = useState({});
    const [showSurveyList, setShowSurveyList] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [certificateLoading, setCertificateLoading] = useState(false);
    const [perPage, setPerPage] = useState(PAGE_SIZE);
    const [currentPage, setCurrentPage] = useState(CURRENT_PAGE);

    const getCertificateRecords = useCallback(
        (page: number, size = perPage) => {
            setCertificateLoading(true);
            // TODO: Need to changed pagination
            console.log('certificateLoading', certificateLoading);
            console.log("================================");
            console.log('certificateLoading', certificateLoading);
            (async function () {
                await getAllCertificate({
                    security: {
                        APIKey: process.env.REACT_APP_API_KEY as string,
                        User: user.attributes?.email as string,
                    },
                    buildingID: "",
                    clientID: Client?.id,
                    showExternal: false,
                    current: page,
                    pagesize: size,
                    sortfield: "",
                });
            })().then(() => {
                setLoading(false);
            });
        },
        [getAllCertificate, perPage, Client, user.attributes?.email]
    );

    useEffect(() => {
        getCertificateRecords(CURRENT_PAGE, perPage);
    }, [perPage, getCertificateRecords]);

    const surveyBread = [
        {
            name: "Brand Management",
            href: "/brand-management",
            isDisabled: false,
        },
        { name: Client?.name, isDisabled: true },
    ];

    const handlePageChange = useCallback(
        (page: number) => {
            setCurrentPage(page);
            getCertificateRecords(page, perPage);
        },
        [getCertificateRecords]
    );

    const handlePerRowsChange = useCallback(
        (newPerPage: number, page: number) => {
            setPerPage(newPerPage);
            setCurrentPage(1);
            getCertificateRecords(page, perPage);
        },
        [getCertificateRecords]
    );

    const closeCertificate = () => {
        setShowForm(false);
        if (certificateId) {
            setCertificateId("");
        }
    };

    const addCertificate = (id?: string) => {
        setShowForm(true);
        if (id) {
            setCertificateId(id);
        }
    };

    const activateCertificate = (id?: string) => {
        console.log("Activate Certificate: ", id);
        // TODO: Need to dynamic this.
    };

    const handleRowClick = (row?: object) => {
        if (row) {
            setSelectedCertificate(row);
            setShowSurveyList(true);
        }
    };

    const handleCreateCertificate = useCallback(
        (d: any) => {
            (async function () {
                await createSurvey(d);
            })().then(function () {
                closeCertificate()
                // TODO: Need to fix this when submi the form.
                setTimeout(() => {
                    getCertificateRecords(CURRENT_PAGE, perPage);
                }, 2000);
            });
        },
        []
    );

    const columns = [
        {
            name: "Certificate",
            selector: (row: any) => row.name,
        },
        {
            name: "Audit Issue",
            selector: (row: any) => "-", // TODO: Need to dynamic this.
        },
        {
            name: "Status",
            selector: (row: any) => "-", // TODO: Need to dynamic this.
        },
        {
            name: "Expires",
            selector: (row: any) => "-", // TODO: Need to dynamic this.
        },
        {
            name: "Assessor",
            selector: (row: any) => {
                if (row.email) {
                    return row.email;
                } else {
                    return "-";
                }
            },
            // TODO: Need to dynamic
        },
        {
            name: "Action",
            button: true,
            style: [{ "justify-content": "left" }],
            cell: (row: any) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 50, hide: 10 }}
                            overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
                        >
                            <img
                                className="iconSizes"
                                src={eyeIcon}
                                alt=""
                                onClick={() => {
                                    handleRowClick(row);
                                }}
                            />
                        </OverlayTrigger>
                        {
                            // TODO: Need to dynamic
                            (row?.active) ? <>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 50, hide: 10 }}
                                    overlay={<Tooltip id="tooltip-table-top">Activate</Tooltip>}
                                >
                                    <img
                                        className="iconSizes"
                                        src={activatedIcon}
                                        alt=""
                                        style={{ height: "20px" }}
                                        onClick={() => {
                                            activateCertificate(row);
                                        }}
                                    />
                                </OverlayTrigger>
                            </> : <>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 50, hide: 10 }}
                                    overlay={<Tooltip id="tooltip-table-top">Activate</Tooltip>}
                                >
                                    <img
                                        className="iconSizes"
                                        src={deactivatedIcon}
                                        alt=""
                                        style={{ height: "20px" }}
                                        onClick={() => {
                                            activateCertificate(row);
                                        }}
                                    />
                                </OverlayTrigger>
                            </>
                        }

                    </>
                );
            },
        },
    ];

    return (
        <>
            <SpinLoader isLoading={IsLoading} />
            {(showSurveyList && (
                <SurveyList selected={selectedCertificate} breadList={surveyBread} />
            )) || (
                    <div style={tabInside}>

                        <Row>
                            <Col md={8}>
                                <BreadCrumbPage
                                    links={[
                                        {
                                            name: "Brand Management",
                                            href: "/brand-management",
                                            isDisabled: false,
                                        },
                                        { name: Client?.name, isDisabled: true },
                                    ]}
                                />
                            </Col>
                            <Col md={4} style={{ display: "flex", justifyContent: "end" }}>
                                <Button
                                    id="approve-button"
                                    className="float-right"
                                    onClick={() => {
                                        addCertificate();
                                    }}
                                >
                                    <b>New Certificate</b>
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "1%" }}>
                            <Col md={12}>
                                <DataTable
                                    columns={columns}
                                    data={Certificates}
                                    striped={true}
                                    customStyles={tableStyles}
                                    theme="mysaTable"
                                    fixedHeader={true}
                                    noDataComponent={<SetTableOptions loading={loading} />}
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    progressPending={loading}
                                    progressComponent={<SpinLoader />}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={TotalRows}
                                    paginationDefaultPage={currentPage}
                                />
                            </Col>
                        </Row>

                        <CertificateAdd
                            certificateId={certificateId}
                            show={showForm}
                            selected={selectedCertificate}
                            closeModal={closeCertificate}
                            manageSection={handleCreateCertificate}
                            client={Client}
                        />
                    </div>
                )}
        </>
    );
};

export default CertificateList;
