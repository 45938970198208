import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation, NavLink } from "react-router-dom";
import { overlayTriggerDelay } from "../common/components-funtion";
import {
  iconDivStyle,
  sideMenuStyle,
  toolTipStyle,
} from "../common/components-style";
import whiteSpaceActivate from "../assets/images/nav-icon/activate/white-space.svg";
import whiteSpaceDeactivate from "../assets/images/nav-icon/deactivate/white-space.svg";
import mysaSureActivate from "../assets/images/nav-icon/activate/mysa-sure.svg";
import mysaSureDeactivate from "../assets/images/nav-icon/deactivate/mysa-sure.svg";
import brandActivate from "../assets/images/nav-icon/activate/brand.svg";
import brandDeactivate from "../assets/images/nav-icon/deactivate/brand.svg";

const SideMenu: React.FC = (): JSX.Element => {
  const location = useLocation();
  return (
    <div style={sideMenuStyle}>
      <NavLink to="/brand-management">
        {location.pathname.includes("/brand-management") ? (
          <OverlayTrigger
            placement="right"
            delay={overlayTriggerDelay}
            overlay={
              <Tooltip id="tooltip-right" style={toolTipStyle}>
                Myo Management
              </Tooltip>
            }
          >
            <div style={iconDivStyle}>
              <img
                src={brandActivate}
                alt="Myo Management"
                className="developer-active"
              />
            </div>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            placement="right"
            delay={overlayTriggerDelay}
            overlay={
              <Tooltip id="tooltip-right" style={toolTipStyle}>
                Myo Management
              </Tooltip>
            }
          >
            <div style={iconDivStyle}>
              <img
                src={brandDeactivate}
                alt="Myo Management"
                className="developer-deactive"
              />
            </div>
          </OverlayTrigger>
        )}
      </NavLink>
      <NavLink to="/digital-audit">
        {location.pathname === "/digital-audit" ? (
          <OverlayTrigger
            placement="right"
            delay={overlayTriggerDelay}
            overlay={
              <Tooltip id="tooltip-right" style={toolTipStyle}>
                Digital Audit
              </Tooltip>
            }
          >
            <div style={iconDivStyle}>
              <img src={whiteSpaceActivate} alt="Digital Audit" />
            </div>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            placement="right"
            delay={overlayTriggerDelay}
            overlay={
              <Tooltip id="tooltip-right" style={toolTipStyle}>
                Digital Audit
              </Tooltip>
            }
          >
            <div style={iconDivStyle}>
              <img src={whiteSpaceDeactivate} alt="Digital Audit" />
            </div>
          </OverlayTrigger>
        )}
      </NavLink>
      <NavLink to="/survey-template">
        {location.pathname === "/survey-template" ? (
          <OverlayTrigger
            placement="right"
            delay={overlayTriggerDelay}
            overlay={
              <Tooltip id="tooltip-right" style={toolTipStyle}>
                MysaSure Template
              </Tooltip>
            }
          >
            <div style={iconDivStyle}>
              <img src={mysaSureActivate} alt="Digital Audit" />
            </div>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            placement="right"
            delay={overlayTriggerDelay}
            overlay={
              <Tooltip id="tooltip-right" style={toolTipStyle}>
                MysaSure Template
              </Tooltip>
            }
          >
            <div style={iconDivStyle}>
              <img src={mysaSureDeactivate} alt="Digital Audit" />
            </div>
          </OverlayTrigger>
        )}
      </NavLink>
    </div>
  );
};

export default SideMenu;
