import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Col, Row, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import {
    mysaTableTheme,
    tableStyles,
    pageHeader,
    tabInside,
} from "../../../common/components-style";
import { SpinLoader } from "../../../utils/spin-loader";
import { SetTableOptions } from "../../../shared/set-table-options";
import DataTable, { createTheme } from "react-data-table-component";
import {
    useStoreActions,
    useStoreState,
} from "../../../store/models/model-hook";
import { CURRENT_PAGE, PAGE_SIZE } from "../../../utils/constants";
import eyeIcon from "../../../assets/images/table/eye.svg";
import searchIcon from "../../../assets/images/table/search.svg";
import editIcon from "../../../assets/images/table/edit.svg";
import yesIcon from "../../../assets/images/table/yes.svg";

createTheme("mysaTable", mysaTableTheme, "light");

const ClientList: React.FC = (): JSX.Element => {
    const navigate = useNavigate();
    const { user } = useAuthenticator((context) => [context.user]);

    const { getAllClient } = useStoreActions((actions) => ({
        getAllClient: actions.clientModel.getAllClient,
    }));

    const { getAllAssesor } = useStoreActions((actions) => ({
        getAllAssesor: actions.assessorModel.getAllAssesor,
    }));

    const { Clients, TotalRows, IsLoading } = useStoreState((state) => ({
        Clients: state.clientModel.Clients,
        TotalRows: state.clientModel.TotalRows,
        ClientNames: state.clientModel.ClientNames,
        IsLoading: state.clientModel.IsLoading,
    }));

    const { Assessors } = useStoreState((state) => ({
        Assessors: state.assessorModel.Assessors,
    }));

    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(PAGE_SIZE);
    const [name, setName] = useState("");
    const [status, setStatus] = useState("");
    const [currentPage, setCurrentPage] = useState(CURRENT_PAGE);
    const [tableData, setTableData] = useState<any[]>([]);
    const [selectedAssessor, setSelectedAssessor] = useState("");

    const getRecords = useCallback(
        (page: number, size = perPage, name: string) => {
            // TODO: Need to dynamic status.
            setLoading(true);
            (async function () {
                await getAllClient({
                    security: {
                        APIKey: process.env.REACT_APP_API_KEY as string,
                        User: user.attributes?.email as string,
                    },
                    type: status,
                    current: page,
                    pagesize: size,
                    sortfield: "",
                    Name: name,
                });
            })().then(() => {
                setLoading(false);
            });
        },
        [getAllClient, perPage, user.attributes?.email]
    );

    const getAssessorData = useCallback(
        (id: string) => {
            setLoading(true);
            (async function () {
                await getAllAssesor({
                    security: {
                        APIKey: process.env.REACT_APP_API_KEY as string,
                        User: user.attributes?.email as string,
                    },
                    id: id
                });
            })().then((data) => {
                setLoading(false);
            });
        },
        [getAllAssesor, user.attributes?.email]
    );

    useEffect(() => {
        getRecords(CURRENT_PAGE, perPage, name);
    }, [perPage, getRecords]);

    useEffect(() => {
        setTableData(Clients); // Update tableData with fetched Clients data
    }, [Clients]);

    const columns = [
        {
            name: "Name",
            selector: (row: any) => row.name,
        },
        {
            name: "Audit Issue",
            selector: (row: any) => "-",
            // TODO: Need to dynamic.
        },
        {
            name: "Status",
            selector: (row: any) => "-",
            // TODO: Need to dynamic.
        },
        {
            name: "Expires",
            selector: (row: any) => "-",
            // TODO: Need to dynamic.
        },
        {
            name: "Assessor",
            selector: (row: any) => {
                if (row.email) {
                    if (row.isEdit) {
                        return (
                            <select className="form-select" onChange={handleAssessorChange}>
                                <option value="-">Select Assessor</option>
                                {Assessors.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.email}
                                    </option>
                                ))}
                            </select>
                        );
                    } else {
                        return row.email;
                    }
                } else {
                    return "-";
                }
            },
        },
        {
            name: "Action",
            button: true,
            cell: (row: any) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 50, hide: 10 }}
                            overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
                        >
                            <img
                                className="iconSizes"
                                src={eyeIcon}
                                alt=""
                                onClick={() => {
                                    navigate(`/brand-management/${row.id}`);
                                }}
                            />
                        </OverlayTrigger>
                        {
                            (row.isEdit) ?
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 50, hide: 10 }}
                                    overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
                                >
                                    <img
                                        className="iconSizes"
                                        src={yesIcon}
                                        alt=""
                                        onClick={() => handleYesClick(row, row?.id)}
                                    />
                                </OverlayTrigger>
                                : (
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 50, hide: 10 }}
                                        overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
                                    >
                                        <img
                                            className="iconSizes"
                                            src={editIcon}
                                            alt=""
                                            onClick={() => handleEditClick(row.id)}
                                        />
                                    </OverlayTrigger>
                                )
                        }

                    </>
                );
            },
        },
    ];

    const handlePageChange = useCallback(
        (page: number) => {
            setCurrentPage(page);
            getRecords(page, perPage, name);
        },
        [getRecords]
    );

    const handlePerRowsChange = useCallback(
        (newPerPage: number, page: number) => {
            setPerPage(newPerPage);
            setCurrentPage(1);
            getRecords(page, perPage, name);
        },
        [getRecords]
    );

    const handleSearch = () => {
        getRecords(CURRENT_PAGE, perPage, name);
    }

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    const handleEditClick = (id: string) => {
        const newData = tableData.map((item) =>
            item.id === id ? { ...item, isEdit: true } : { ...item, isEdit: false }
        );
        setTableData(newData);
        getAssessorData(id)
    };

    const handleAssessorChange = (event: any) => {
        setSelectedAssessor(event.target.value);
    }

    const handleYesClick = (row: object, id: string) => {
        const newData = tableData.map((item) =>
            item.id === id ? { ...item, isEdit: false } : { ...item, isEdit: false }
        );
        setTableData(newData);
        // TODO: Need to update assesor.
    }
    
    return (
        <>
            <SpinLoader isLoading={IsLoading} />
            <div style={pageHeader}>
                <h3>Brand Management</h3>
            </div>

            <div style={tabInside}>
                <div className="custome-filter-brand">
                    <Row style={{ marginTop: "1%" }}>
                        <Col md={12}>
                            <Row className="mb-2 d-flex align-items-center">
                                <Col md={2}>
                                    <label className="form-label mb-0">Filter by Status</label>
                                </Col>
                                <Col md={2}>
                                    <select className="form-select">
                                        <option value="0" defaultValue="0">In Progress</option>
                                        <option value="1">Pending Approval</option>
                                        <option value="1">Issued</option>
                                        <option value="1">Rejected</option>
                                        <option value="1">Under Review</option>
                                    </select>
                                </Col>
                                <Col md={3}>
                                    <input type="text"
                                        onChange={onInputChange}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Button
                                        id="approve-button"
                                        className="create-btn"
                                        style={{ backgroundColor: "#fAC90E", color: "#00000" }}
                                        onClick={() => handleSearch()}
                                    >
                                        <img src={searchIcon} alt="add item" width="30" /> Search
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row style={{ marginTop: "1%" }}>
                    <Col md={12}>
                        <DataTable
                            columns={columns}
                            data={tableData}
                            striped={true}
                            customStyles={tableStyles}
                            theme="mysaTable"
                            fixedHeader={true}
                            noDataComponent={<SetTableOptions loading={loading} />}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            progressPending={loading}
                            progressComponent={<SpinLoader />}
                            pagination
                            paginationServer
                            paginationTotalRows={TotalRows}
                            paginationDefaultPage={currentPage}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ClientList;
