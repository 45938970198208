import { Action, action, thunk, Thunk } from "easy-peasy";
import axios from "../../../common/axios";
import NotificationAlert from "../../../common/notification-alert";

interface ICertificateRequest {
  security?: {
    APIKey: string;
    User: string;
  };
  id?: string;
}

interface ICertificatesRequest {
  security?: {
    APIKey: string;
    User: string;
  };
  buildingID?: string;
  clientID?: string;
  current?: number;
  pagesize?: number;
  sortfield?: string;
  showExternal?: boolean;
}

export interface ICertificate {
  id?: string;
  name?: string;
  assessor?: string;
  inviteExpiresOn?: string;
  inviteIssuedOn?: string;
  status?: string;
  surveys?: []
}

interface ICertificates {
  Certificates: ICertificate[];
  Certificate?: ICertificate;
  TotalRows: number;
  IsLoading: boolean;
}

interface ICertificateAction {
  setAllCertificate: Action<this, ICertificates>;
  setCertificate: Action<this, ICertificates>;
  setTotalRows: Action<this, number>;
  setIsLoading: Action<this, boolean>;
}
interface ICertificateThunk {
  // getAllCertificateForFilter: Thunk<this, ICertificatesRequest>;
  getAllCertificate: Thunk<this, ICertificatesRequest>;
  createSurvey:  Thunk<this, ICertificatesRequest>;
  getCertificateById: Thunk<this, ICertificateRequest>;
}

interface ICertificateModel
  extends ICertificates,
    ICertificateAction,
    ICertificateThunk {}

const certificateModel: ICertificateModel = {
  Certificates: [],
  Certificate: {},
  TotalRows: 0,
  IsLoading: true,
  setAllCertificate: action((state, payload) => {
    state.Certificates = payload.Certificates;
  }),
  setCertificate: action((state, payload) => {
    state.Certificate = payload.Certificate;
  }),
  setTotalRows: action((state, payload) => {
    state.TotalRows = payload;
  }),

  getAllCertificate: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/certificate/Search", payload)
      .then((value: any) => {
        let temCertificate: ICertificates = {
          Certificates: [],
          TotalRows: 0,
          IsLoading: false,
        };
        value.data.certificates.forEach((element: any) => {
          temCertificate.Certificates?.push({
            id: element.id,
            name: element.name,
            inviteExpiresOn: element?.inviteExpiresOn,
            inviteIssuedOn: element?.inviteIssuedOn,
            status: "",
          });
        });

        action.setAllCertificate(temCertificate);
        action.setTotalRows(temCertificate.Certificates.length);
        action.setIsLoading(false);
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Error from api"
        );
        action.setIsLoading(false);
      });
  }),

  getCertificateById: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/certificate/view", payload)
      .then((value: any) => {
        if (value.data.certificate) {
          let temCertificate: ICertificates = {
            Certificate: {
              id: value.data.certificate.id,
              name: value.data.certificate.name,
              inviteExpiresOn: value.data.certificate.inviteExpiresOn,
              inviteIssuedOn: value.data.certificate.inviteIssuedOn,
              status: "",
              surveys: value.data.certificate.surveys,
            },
            Certificates: [],
            TotalRows: 0,
            IsLoading: false,
          };
          action.setCertificate(temCertificate);
          action.setIsLoading(false);
        }
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Error from api"
        );
        action.setIsLoading(false);
      });
  }),
  createSurvey: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/certificate/create", payload)
      .then((value: any) => {
        action.setIsLoading(false);
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Error from api"
        );
        action.setIsLoading(false);
      });
  }),
  setIsLoading: action((state, payload) => {
    state.IsLoading = payload;
  }),
};

export default certificateModel;
