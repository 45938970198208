import { Tab, Row, Col, Nav } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  navContentStyle,
  navStyle,
  tabDivStyle,
} from "../../../common/components-style";
import {
  useStoreActions,
  useStoreState,
} from "../../../store/models/model-hook";
// import ClientDetail from "../../brand-management/brand-detail";
// import PropertyList from "../../brand-management/brand-detail/property/propertyList";
// import { CertificateList } from "../../brand-management/brand-detail/certificate";
import CertificateDetails from "./details/CertificateDetails";
import Certificate from "./certificate/certificate";

const PropertyDetails: React.FC<any> = (): JSX.Element => {
  const [tabKey, setTabKey] = useState<any>("details");
  let { buildingId } = useParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const { getPropertyByIdView } = useStoreActions((actions) => ({
    getPropertyByIdView: actions.propertyModel.getPropertyByIdView,
  }));
  const { Property, IsLoading } = useStoreState((state) => ({
    Property: state.propertyModel.Property,
    IsLoading: state.propertyModel.IsLoading,
  }));

  useEffect(() => {
    (async function () {
      await getPropertyByIdView({
        security: {
          APIKey: process.env.REACT_APP_API_KEY as string,
          User: user.attributes?.email as string,
        },
        id: buildingId,
      });
    })();
  }, [getPropertyByIdView, buildingId, user.attributes?.email]);
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  
  return (
    <div style={tabDivStyle}>
      <Tab.Container
        defaultActiveKey="details"
        key={tabKey}
        activeKey={tabKey}
        onSelect={(tab) => setTabKey(tab)}
      >
        <Row>
          <Col sm={2}>
            <Nav
              variant="pills"
              className="flex-column secondry-menu"
              style={navStyle}
            >
              <div style={{ marginTop: "0%" }}>
                <Nav.Item>
                  <Nav.Link eventKey="details">Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="certificates">Certificates</Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Tab.Pane eventKey="details">
                <div style={navContentStyle}>
                  <CertificateDetails selected={Property}/>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="certificates">
                <div style={navContentStyle}>
                  <Certificate selected={Property}/>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default PropertyDetails;
