import { useAuthenticator } from "@aws-amplify/ui-react";
import React from "react";
import {
  Navbar,
  OverlayTrigger,
  Popover,
  Card,
  ListGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import brandLogo from "../assets/images/logo/brand.svg";
import userProfile from "../assets/images/user-profile.svg";
const AppHeader: React.FC = (): JSX.Element => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
    <>
      <Navbar bg="light" expand="lg" fixed="top" className="navbar-inner">
        <Navbar.Brand href="/" style={{ marginLeft: "4%" }}>
          <img width="195" src={brandLogo} alt="brandLogo" />
        </Navbar.Brand>
        <Navbar.Toggle style={{ marginRight: "4%" }} />
        <Navbar.Collapse
          className="justify-content-end"
          style={{ marginRight: "4%" }}
        >
          <Navbar.Text>
            Welcome, <span style={{ fontWeight: "bold" }}>{user.username}</span>
          </Navbar.Text>
          <Navbar.Brand>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              rootClose
              overlay={
                <Popover placement="bottom">
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        <ListGroup>
                          <ListGroup.Item>
                            <b>
                              <Link
                                to="#"
                                onClick={() => {
                                  signOut();
                                }}
                              >
                                Logout
                              </Link>
                            </b>
                          </ListGroup.Item>
                        </ListGroup>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Popover>
              }
            >
              <Link to="#">
                <img width="50" src={userProfile} alt="userProfileIcon" />
              </Link>
            </OverlayTrigger>
          </Navbar.Brand>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default AppHeader;
