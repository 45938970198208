import React from "react";
import { Col, Row } from "react-bootstrap";
import { tabDivStyle, navContentStyle } from "../../../common/components-style";
import ClientList from "./clientList";

const Brand: React.FC = (): JSX.Element => {
  return (
    <div style={tabDivStyle}>
      <Row>
        <Col sm={12}>
          <div style={navContentStyle}>
            <ClientList />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Brand;
