import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import {
    mysaTableTheme,
    tableStyles,
    tabInside,
} from "../../../../common/components-style";
import BreadCrumbPage from "../../../../common/bread-crumb-page";
import { SpinLoader } from "../../../../utils/spin-loader";
import { SetTableOptions } from "../../../../shared/set-table-options";
import DataTable, { createTheme } from "react-data-table-component";
import {
    useStoreActions,
    useStoreState,
} from "../../../../store/models/model-hook";
import { CURRENT_PAGE, PAGE_SIZE } from "../../../../utils/constants";
import eyeIcon from "../../../../assets/images/table/eye.svg";

createTheme("mysaTable", mysaTableTheme, "light");

const PropertyList: React.FC<any> = ({ Client }): JSX.Element => {
    const { user } = useAuthenticator((context) => [context.user]);
    const { getAllProperty } = useStoreActions((actions) => ({
        getAllProperty: actions.propertyModel.getAllProperty,
    }));

    const { Properties, TotalRows, IsLoading } = useStoreState((state) => ({
        Properties: state.propertyModel.Properties,
        TotalRows: state.propertyModel.TotalRows,
        IsLoading: state.propertyModel.IsLoading,
    }));

    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(PAGE_SIZE);

    const [selectedProperties, setSelectedProperties] = useState({});
    const [showSurveyList, setShowSurveyList] = useState(false);

    const [currentPage, setCurrentPage] = useState(CURRENT_PAGE);
    const navigate = useNavigate();

    const getRecords = useCallback(
        (page: number, size = perPage) => {
            setLoading(true);
            (async function () {
                await getAllProperty({
                    security: {
                        APIKey: process.env.REACT_APP_API_KEY as string,
                        User: user.attributes?.email as string,
                    },
                    filter: "",
                    clientID: Client?.id,
                    current: page,
                    pagesize: size,
                    sortField: "",
                });
            })().then(() => {
                setLoading(false);
            });
        },
        [getAllProperty, perPage, Client, user.attributes?.email]
    );

    useEffect(() => {
        getRecords(CURRENT_PAGE, perPage);
    }, [perPage, getRecords]);

    const columns = [
        {
            name: "Name",
            selector: (row: any) => row.name,
        },
        {
            name: "City",
            selector: (row: any) => row.city,
        },
        {
            name: "Audit Issue",
            selector: (row: any) => "-", // TODO: Need to dynamic
        },
        {
            name: "Certificate Status",
            selector: (row: any) => "-", // TODO: Need to dynamic
        },
        {
            name: "Expires",
            selector: (row: any) => "-", // TODO: Need to dynamic
        },
        {
            name: "Assessor",
            selector: (row: any) => {
                if (row.assessorInfo) {
                    return row.assessorInfo.email;
                } else {
                    return "-";
                }
            },
        },
        {
            name: "Action",
            button: true,
            cell: (row: any) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 50, hide: 10 }}
                            overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
                        >
                            <img
                                className="iconSizes"
                                src={eyeIcon}
                                alt=""
                                onClick={() => {
                                    navigate(`/property-management/${row.id}`);
                                }}
                            />
                        </OverlayTrigger>
                    </>
                );
            },
        },
    ];

    const handlePageChange = useCallback(
        (page: number) => {
            setCurrentPage(page);
            getRecords(page);
        },
        [getRecords]
    );

    const handlePerRowsChange = useCallback(
        (newPerPage: number, page: number) => {
            setPerPage(newPerPage);
            setCurrentPage(1);
            getRecords(page);
        },
        [getRecords]
    );

    // const handleRowClick = () => {
    //   setShowSurveyList(true);
    // };

    return (
        <>
            <SpinLoader isLoading={IsLoading} />
            <div style={tabInside}>
                <Row>
                    <Col md={8}>
                        <BreadCrumbPage
                            links={[
                                {
                                    name: "Brand Management",
                                    href: "/brand-management",
                                    isDisabled: false,
                                },
                                { name: Client?.name, isDisabled: true },
                            ]}
                        />
                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Row style={{ marginTop: "1%" }}>
                    <Col md={12}>
                        <DataTable
                            columns={columns}
                            data={Properties}
                            striped={true}
                            customStyles={tableStyles}
                            theme="mysaTable"
                            fixedHeader={true}
                            noDataComponent={<SetTableOptions loading={loading} />}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            progressPending={loading}
                            progressComponent={<SpinLoader />}
                            pagination
                            paginationServer
                            paginationTotalRows={TotalRows}
                            paginationDefaultPage={currentPage}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default PropertyList;
